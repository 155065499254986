<!--
 * @Author: ch3nh2
 * @Date: 2022-11-01 09:35:26
 * @LastEditors: ch3nh2
 * @LastEditTime: 2023-01-10 09:50:46
 * @FilePath: /beilunchanye_fe_20211208/src/views/dahang.vue
 * @Description: 
-->
<template>
  <div class="home dahang">
    <div v-show="$store.state.animateDelay && $store.state.mapType" class="dahang-wrap dahang-left-wrap"
      :class="{ 'animate__animated': true, 'animate__fadeInLeftBig': $store.state.showDataValue, 'animate__fadeOutLeftBig': !$store.state.showDataValue }">
      <!-- 基本情况 -->
      <div class="dahang-item dahang-item-01">
        <div class="dahang-item-title-img">
          <div class="dahang-item-title">
            <div class="dahang-item-title-icon"></div>
            <p>基本情况</p>
            <span>/Basic information</span>
          </div>
        </div>
        <div class="dahang-item-content">
          <div class="dahang-item-content-data">
            <div class="dahang-item-content-data-item">
              <div class="dahang-item-content-data-icon"></div>
              <div class="dahang-item-content-data-data">
                <div class="dahang-item-content-data-name">建筑面积(㎡)</div>
                <div class="dahang-item-content-data-value">
                  <CountUp v-if="data && data.build_area" :delay="1000" :endVal="data.build_area"
                    :options="{ decimalPlaces: 1 }" />
                  <span v-else>-</span>
                </div>
              </div>
            </div>
            <div class="dahang-item-content-data-item">
              <div class="dahang-item-content-data-icon"></div>
              <div class="dahang-item-content-data-data">
                <div class="dahang-item-content-data-name">楼层数(层)</div>
                <div class="dahang-item-content-data-value">
                  地上
                  <CountUp v-if="data && data.ground_floor" :delay="1000" :endVal="data.ground_floor" />
                  <span v-else>-</span>
                  层
                  地下
                  <CountUp v-if="data && data.ground_floor" :delay="1000" :endVal="data.underground_floor" />
                  <span v-else>-</span>
                  层
                </div>
              </div>
            </div>
            <div class="dahang-item-content-data-item">
              <div class="dahang-item-content-data-icon"></div>
              <div class="dahang-item-content-data-data">
                <div class="dahang-item-content-data-name">层高(米)</div>
                <div class="dahang-item-content-data-value">
                  <CountUp v-if="data && data.floor_height" :delay="1000" :endVal="data.floor_height"
                    :options="{ decimalPlaces: 1 }" />
                  <span v-else>-</span>
                </div>
              </div>
            </div>
            <!-- <div class="dahang-item-content-data-item">
              <div class="dahang-item-content-data-icon"></div>
              <div class="dahang-item-content-data-data">
                <div class="dahang-item-content-data-name">承重(KG/㎡)</div>
                <div class="dahang-item-content-data-value">
                  <CountUp :delay="1000" :endVal="150" />
                </div>
              </div>
            </div> -->
            <div class="dahang-item-content-data-item">
              <div class="dahang-item-content-data-icon"></div>
              <div class="dahang-item-content-data-data">
                <div class="dahang-item-content-data-name">租金报价(元)</div>
                <div class="dahang-item-content-data-value">
                  <CountUp v-if="data && data.rent_quote" :delay="1000" :endVal="data.rent_quote" />
                  <span v-else>-</span>
                  {{`(${data && data.management_fee ? '包含' : '不包含'}物业管理费)`}}
                </div>
              </div>
            </div>
            <div class="dahang-item-content-data-item">
              <div class="dahang-item-content-data-icon"></div>
              <div class="dahang-item-content-data-data">
                <div class="dahang-item-content-data-name">车位(元/月)</div>
                <div class="dahang-item-content-data-value">
                  地下
                  <CountUp v-if="data && data.ground_parking_fee" :delay="1000" :endVal="data.ground_parking_fee" />
                  <span v-else>-</span>
                  地上
                  <CountUp v-if="data && data.underground_parking_fee" :delay="1000"
                    :endVal="data.underground_parking_fee" />
                  <span v-else>-</span>
                  立体车库
                  <CountUp v-if="data && data.td_parking_fee" :delay="1000" :endVal="data.td_parking_fee" />
                  <span v-else>-</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 项目分析 -->
      <div class="dahang-item dahang-item-02">
        <div class="dahang-item-title-img">
          <div class="dahang-item-title">
            <div class="dahang-item-title-icon"></div>
            <p>项目分析</p>
            <span>/Project analysis</span>
          </div>
        </div>
        <div class="dahang-item-content">
          <div class="dahang-item-content-data">
            <div class="dahang-item-content-data-nav">
              <div class="dahang-item-content-data-nav-item">
                <div class="dahang-item-content-data-nav-icon"></div>
                <div class="dahang-item-content-data-nav-name">项目介绍</div>
              </div>
              <div class="dahang-item-content-data-nav-item">
                <div class="dahang-item-content-data-nav-icon"></div>
                <div class="dahang-item-content-data-nav-name">区域优势</div>
              </div>
              <div class="dahang-item-content-data-nav-item">
                <div class="dahang-item-content-data-nav-icon"></div>
                <div class="dahang-item-content-data-nav-name">交通优势</div>
              </div>
            </div>
            <div class="dahang-item-content-data-text">
              项目名称为“大行基业大厦”位于北京市海淀区人大北路33号，房屋于2001年
              建成，总土地面积2121.96㎡、建筑面积建筑面积33071.4㎡平方米；2019年对公共空间进行了重新装修，目前成新率约55%的；5A智能型写字楼；配备3部客梯，1部货梯。地处中关村信息枢纽黄金地带，南依白颐路，毗邻清华、北大、
              人大等著名高等学府。交通便利，配套设施齐全。
            </div>
            <div class="dahang-item-content-data-img"></div>
          </div>
        </div>
      </div>
    </div>
    <div v-show="$store.state.animateDelay && $store.state.mapType" class="dahang-wrap dahang-right-wrap"
      :class="{ 'animate__animated': true, 'animate__fadeInRightBig': $store.state.showDataValue, 'animate__fadeOutRightBig': !$store.state.showDataValue }">
      <!-- 项目招商情况 -->
      <div class="dahang-item dahang-item-03">
        <div class="dahang-item-title-img">
          <div class="dahang-item-title">
            <div class="dahang-item-title-icon"></div>
            <p>项目招商情况</p>
            <span>/Device Information</span>
          </div>
        </div>
        <div class="dahang-item-content">
          <div class="dahang-item-content-data">
            <div class="dahang-item-content-data-top">
              <div class="dahang-item-content-data-item">
                <div class="dahang-item-content-data-icon">
                  <CountUp v-if="data && data.full_rent_area" :delay="1000" :endVal="data.full_rent_area"
                    :options="{ decimalPlaces: 1 }" />
                  <span v-else>-</span>
                </div>
                <div class="dahang-item-content-data-name">租赁总面积(㎡)</div>
              </div>
              <div class="dahang-item-content-data-item">
                <div class="dahang-item-content-data-icon">
                  <CountUp v-if="data && data.company_nm" :delay="1000" :endVal="data.company_nm" />
                  <span v-else>-</span>
                </div>
                <div class="dahang-item-content-data-name">企业数(家)</div>
              </div>
              <div class="dahang-item-content-data-item">
                <div class="dahang-item-content-data-icon">
                  <CountUp v-if="data && data.employee_nm" :delay="1000" :endVal="data.employee_nm" />
                  <span v-else>-</span>
                </div>
                <div class="dahang-item-content-data-name">员工数(人)</div>
              </div>
            </div>
            <div class="dahang-item-content-data-bottom">
              <div class="dahang-item-content-data-chart">
                <div id="Chart" :style="{ width: '108px', height: '108px' }"></div>
                <div class="dahang-item-content-data-chart-wrap dahang-item-content-data-chart-dot"></div>
                <div class="dahang-item-content-data-chart-wrap dahang-item-content-data-chart-circle"></div>
                <div class="dahang-item-content-data-chart-wrap dahang-item-content-data-chart-data">
                  <div class="dahang-item-content-data-chart-value">
                    <CountUp v-if="data && (data.full_rent_area && data.rent_area)" :delay="1000"
                      :endVal="data.rent_area / data.full_rent_area * 100" />
                    <span v-else>-</span>
                    %
                  </div>
                  <div class="dahang-item-content-data-chart-name">去化率</div>
                </div>
              </div>
              <div class="dahang-item-content-data-data">
                <div class="dahang-item-content-data-data-item">
                  <div class="dahang-item-content-data-data-content">
                    <div class="dahang-item-content-data-data-value">
                      <CountUp v-if="data && data.rent_area" :delay="1000" :endVal="data.rent_area"
                        :options="{ decimalPlaces: 1 }" />
                      <span v-else>-</span>
                    </div>
                    <div class="dahang-item-content-data-data-name">已租赁面积(㎡)</div>
                  </div>
                </div>
                <div class="dahang-item-content-data-data-item">
                  <div class="dahang-item-content-data-data-content">
                    <div class="dahang-item-content-data-data-value">
                      <CountUp v-if="data && data.not_rent_area" :delay="1000" :endVal="data.not_rent_area"
                        :options="{ decimalPlaces: 1 }" />
                      <span v-else>-</span>
                    </div>
                    <div class="dahang-item-content-data-data-name">未租赁面积(㎡)</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 园区企业 -->
      <div class="dahang-item dahang-item-04">
        <div class="dahang-item-title-img">
          <div class="dahang-item-title">
            <div class="dahang-item-title-icon"></div>
            <p>园区企业</p>
            <span>/Enterprises in the park</span>
          </div>
        </div>
        <div class="dahang-item-content">
          <div class="dahang-item-content-data" v-if="list.length > 0">
            <div v-for="(item, index) in list" :key="index" class="dahang-item-content-data-item"
              :class="{ 'dahang-item-content-data-active': (itemData && itemData.company_name == item.company_name) }"
              @click="onItem(item, index)">
              <div class="dahang-item-content-data-icon"></div>
              <div class="dahang-item-content-data-name">{{ item.company_name }}</div>
            </div>
          </div>
          <div class="dahang-nodata" v-else>当前暂无列表数据</div>
        </div>
      </div>
    </div>
    <CompanyDetails name="dahang" :visible="visible" :data="itemData"
      :onClose="() => { itemData = null; visible = !visible }" />
  </div>
</template>

<script>
import { getCompanyList } from '@/api/zgc';
import { getDaHangData } from '@/api'
import CountUp from "vue-countup-v2";
import CompanyDetails from "@/components/companyDetails";

export default {
  name: 'DaHang',
  data() {
    return {
      data: null,
      list: [],
      visible: false,
      itemData: null
    }
  },
  watch: {

  },
  computed: {

  },
  components: {
    CountUp,
    CompanyDetails
  },
  methods: {
    initScale() {
      window.onload = function () {
        adjust()
        window.onresize = function () {
          adjust()
        }
      }
      const adjust = function () {
        let winWidth = document.documentElement.clientWidth
        let body = document.getElementsByTagName('body')[0]
        let scale = winWidth / 1920
        body.style.cssText = 'transform:scale(' + scale + ');'
      }
    },
    drawChart() {
      const chart = this.$echarts.init(document.getElementById('Chart'))
      chart.setOption({
        color: ['#4AFAE3', '#0EB19C'],
        series: [
          {
            type: 'pie',
            radius: ['80%', '100%'],
            label: {
              show: false,
            },
            data: [
              this.data?.rent_area / this.data?.full_rent_area * 100,
              this.data?.not_rent_area / this.data?.full_rent_area * 100
            ]
          }
        ]
      })
    },
    getData() {
      getCompanyList({
        type: 4,
        limit: 999
      }).then((res) => {
        console.log('获取企业列表数据', res)
        const { data } = res;
        data.map((item) => {
          const company_industry = [];
          item.company_keywords = item.company_keywords ? JSON.parse(item.company_keywords) : [];
          item.patent_star = item.patent_star ? JSON.parse(item.patent_star) : null;
          item.intellectual_property_statistics = item.intellectual_property_statistics ? JSON.parse(item.intellectual_property_statistics) : null
          item.rd_efficiency = item.rd_efficiency ? JSON.parse(item.rd_efficiency) : null
          item.distribution = item.distribution ? JSON.parse(item.distribution) : []
          item.company_industry = item.company_industry ? JSON.parse(item.company_industry) : []
          item.company_industry.map((item) => {
            if (item.children && item.children.length > 0) {
              item.children.map((jtem) => {
                company_industry.push({
                  value: jtem.industry_id,
                  label: jtem.industry_name
                })
              })
            }
          })
          item.company_industry = company_industry
          item.grade = item.grade ? JSON.parse(item.grade) : []
          item.tech_rd_strength = item.tech_rd_strength ? JSON.parse(item.tech_rd_strength) : []
        })
        this.list = data
      })
    },
    onItem(item, index) {
      this.itemData = item;
      this.visible = false
      setTimeout(() => {
        this.visible = true
      }, 100);
    },
    getDaHangData() {
      getDaHangData().then((res) => {
        const { data } = res;
        this.data = data;
        this.drawChart()
      })
    }
  },
  mounted() {
    this.getData()
    this.initScale()
    this.getDaHangData()

  },
  beforeDestory() {

  }
}
</script>


<style lang="less">
.dahang {
  .dahang-wrap {
    z-index: 10;
    position: absolute;
    width: 420px;
    height: 100%;
  }

  .dahang-left-wrap {
    left: 30px;
  }

  .dahang-right-wrap {
    right: 30px;
  }

  .dahang-item {
    position: absolute;
    background: rgba(7, 11, 22, 0.7);
    border: 1px solid #FFFFFF;

    .dahang-item-title-img {
      position: absolute;
      width: 418px;
      height: 38px;
      background-image: url(../assets/images/dahang/title.png);
      background-size: 100%;
      background-repeat: no-repeat;

      .dahang-item-title {
        position: absolute;
        display: flex;
        align-items: center;
        width: 100%;
        height: 32px;

        .dahang-item-title-icon {
          width: 20px;
          height: 20px;
          margin: 0 6px;
          background-image: url(../assets/images/dahang/title-icon.png);
          background-size: 100%;
          background-repeat: no-repeat;
        }

        p {
          font-size: 20px;
          font-weight: 500;
          color: #FFFFFF;
        }

        span {
          margin-left: 10px;
          font-size: 14px;
          font-weight: 400;
          color: #FFFFFF;
          opacity: 0.6;
        }
      }
    }

    .dahang-item-content {
      position: absolute;
      width: 100%;
      top: 38px;
    }
  }

  .dahang-item-01 {
    width: 420px;
    height: 365px;
    top: 91px;

    .dahang-item-content {
      height: 329px;

      .dahang-item-content-data {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        margin: 0 14px;

        .dahang-item-content-data-item {
          display: flex;
          align-items: center;
          min-width: 165px;
          margin-top: 28px;

          &:nth-child(1) {
            .dahang-item-content-data-icon {
              background-image: url(../assets/images/dahang/item-01-icon-01.png);
            }
          }

          &:nth-child(2) {
            .dahang-item-content-data-icon {
              background-image: url(../assets/images/dahang/item-01-icon-02.png);
            }
          }

          &:nth-child(3) {
            .dahang-item-content-data-icon {
              background-image: url(../assets/images/dahang/item-01-icon-03.png);
            }
          }

          &:nth-child(4) {
            .dahang-item-content-data-icon {
              background-image: url(../assets/images/dahang/item-01-icon-04.png);
            }
          }

          &:nth-child(5) {
            .dahang-item-content-data-icon {
              background-image: url(../assets/images/dahang/item-01-icon-05.png);
            }
          }

          &:nth-child(6) {
            .dahang-item-content-data-icon {
              background-image: url(../assets/images/dahang/item-01-icon-06.png);
            }
          }

          .dahang-item-content-data-icon {
            width: 66px;
            height: 48px;
            background-size: 100%;
            background-repeat: no-repeat;
          }

          .dahang-item-content-data-data {
            margin-left: 4px;

            .dahang-item-content-data-name {
              font-size: 14px;
              font-weight: 400;
              color: #FFFFFF;
            }

            .dahang-item-content-data-value {
              font-size: 14px;
              font-weight: 400;
              color: #FFFFFF;

              span {
                font-size: 28px;
                font-weight: bold;
                color: #00C6FF;
                font-family: 'bigdsfont';
              }
            }
          }
        }
      }
    }
  }

  .dahang-item-02 {
    width: 420px;
    height: 574px;
    top: 476px;

    .dahang-item-content {
      height: 535px;

      .dahang-item-content-data {
        margin: 18px;

        .dahang-item-content-data-nav {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 30px;

          .dahang-item-content-data-nav-item {
            display: flex;
            flex-direction: column;
            align-items: center;

            &:nth-child(1) {
              .dahang-item-content-data-nav-icon {
                background-image: url(../assets/images/dahang/item-02-icon-01.png);
              }
            }

            &:nth-child(2) {
              .dahang-item-content-data-nav-icon {
                background-image: url(../assets/images/dahang/item-02-icon-02.png);
              }
            }

            &:nth-child(3) {
              .dahang-item-content-data-nav-icon {
                background-image: url(../assets/images/dahang/item-02-icon-03.png);
              }
            }

            .dahang-item-content-data-nav-icon {
              width: 80px;
              height: 64px;
              background-size: 100%;
              background-repeat: no-repeat;
            }

            .dahang-item-content-data-nav-name {
              font-size: 16px;
              font-weight: 500;
              color: #FFFFFF;
              opacity: 0.8;
              margin-top: 8px;
            }
          }
        }

        .dahang-item-content-data-text {
          margin-bottom: 18px;
          font-size: 14px;
          font-family: PingFang SC;
          font-weight: 400;
          color: #FFFFFF;
          line-height: 20px;
        }

        .dahang-item-content-data-img {
          height: 214px;
          background-image: url(../assets/images/dahang/item-02-img.png);
          background-size: 100%;
          background-repeat: no-repeat;
        }
      }
    }
  }

  .dahang-item-03 {
    width: 420px;
    height: 365px;
    top: 91px;

    .dahang-item-content {
      height: 329px;

      .dahang-item-content-data {
        margin: 20px 18px;

        .dahang-item-content-data-top {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 34px;

          .dahang-item-content-data-item {
            display: flex;
            flex-direction: column;
            align-items: center;

            &:nth-child(1) {
              .dahang-item-content-data-icon {
                background-image: url(../assets/images/dahang/item-03-icon-01.png);
              }
            }

            &:nth-child(2) {
              .dahang-item-content-data-icon {
                background-image: url(../assets/images/dahang/item-03-icon-02.png);
              }
            }

            &:nth-child(3) {
              .dahang-item-content-data-icon {
                background-image: url(../assets/images/dahang/item-03-icon-03.png);
              }
            }

            .dahang-item-content-data-icon {
              display: flex;
              justify-content: center;
              width: 106px;
              height: 68px;
              margin-bottom: 8px;
              background-size: 100%;
              background-repeat: no-repeat;

              span {
                font-size: 34px;
                font-weight: bold;
                color: #FFFFFF;
                font-family: 'bigdsfont';
              }
            }

            .dahang-item-content-data-name {
              font-size: 14px;
              font-weight: 400;
              color: #FFFFFF;
            }
          }
        }

        .dahang-item-content-data-bottom {
          display: flex;
          align-items: center;
          justify-content: space-between;

          .dahang-item-content-data-chart {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 50%;

            .dahang-item-content-data-chart-wrap {
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
            }

            .dahang-item-content-data-chart-dot {
              width: 154px;
              height: 154px;
              border: 2px dotted #0EB19C;
              opacity: 0.8;
              border-radius: 50%;
            }

            .dahang-item-content-data-chart-circle {
              width: 134px;
              height: 134px;
              border: 2px solid #4AFAE3;
              border-radius: 50%;
            }

            .dahang-item-content-data-chart-data {
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;

              .dahang-item-content-data-chart-value {
                font-size: 20px;
                font-weight: bold;
                color: #FFFFFF;

                span {
                  font-size: 48px;
                  font-family: 'bigdsfont';
                }
              }

              .dahang-item-content-data-chart-name {
                font-size: 16px;
                font-weight: 400;
                color: #4AFAE3;
              }
            }
          }

          .dahang-item-content-data-data {
            width: 50%;

            .dahang-item-content-data-data-item {
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;
              width: 194px;
              height: 76px;
              background: rgba(7, 32, 96, 0.7);
              border: 1px solid #202E96;
              margin-bottom: 10px;

              &:last-child {
                margin-bottom: 0;
              }

              .dahang-item-content-data-data-content {
                display: flex;
                flex-direction: column;
                align-items: center;
                width: 182px;
                height: 62px;
                background: rgba(33, 73, 174, 0.2);

                .dahang-item-content-data-data-value {
                  margin: 4px 0;
                  font-size: 30px;
                  font-weight: bold;
                  color: #FFFFFF;
                  font-family: 'bigdsfont';
                }

                .dahang-item-content-data-data-name {
                  font-size: 14px;
                  font-weight: 400;
                  color: #17ECFF;
                }
              }
            }
          }
        }
      }
    }
  }

  .dahang-item-04 {
    width: 420px;
    height: 574px;
    top: 476px;

    .dahang-item-content {
      height: 535px;
      overflow-y: scroll;

      &::-webkit-scrollbar {
        display: none;
      }

      .dahang-item-content-data {
        margin: 20px;

        .dahang-item-content-data-item {
          display: flex;
          align-items: center;
          width: 380px;
          height: 40px;
          background: rgba(5, 49, 107, 0.4);
          margin-bottom: 10px;
          cursor: pointer;

          &:last-child {
            margin-bottom: 0;
          }

          .dahang-item-content-data-icon {
            width: 30px;
            height: 30px;
            margin-left: 12px;
            margin-right: 6px;
            background-size: 100%;
            background-repeat: no-repeat;
            background-image: url(../assets/images/dahang/item-04-icon-n.png);
          }

          .dahang-item-content-data-name {
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            font-size: 16px;
            font-weight: 400;
            color: #FFFFFF;
            transition: all .3s;
          }
        }
      }

      .dahang-item-content-data .dahang-item-content-data-active {
        width: 380px;
        height: 40px;
        background: rgba(8, 91, 169, 0.7);
        border: 1px solid #0ED4FF;

        .dahang-item-content-data-icon {
          background-image: url(../assets/images/dahang/item-04-icon-a.png);
        }

        .dahang-item-content-data-name {
          font-size: 18px;
          font-weight: bold;
          color: #FFFFFF;
          transition: all .3s;
        }
      }

      .dahang-nodata {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        color: #ffffff;
      }
    }
  }
}
</style>