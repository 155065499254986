/*
 * @Author: ch3nh2
 * @Date: 2023-01-09 10:45:30
 * @LastEditors: ch3nh2
 * @LastEditTime: 2023-01-09 10:49:12
 * @FilePath: /beilunchanye_fe_20211208/src/api/index.js
 * @Description: none
 */

import service from '@/utils/http'

// 集团总览
export function getGroupData() {
  return service({
    url: 'group_manager_fe',
    method: 'get'
  })
}

// 大行基业总览
export function getDaHangData() {
  return service({
    url: 'dahang_fe',
    method: 'get'
  })
}

// 海星医药健康创新园C区总览
export function getHaiXingData() {
  return service({
    url: 'haixing_fe',
    method: 'get'
  })
}

// 其他资产总览
export function getOtherData() {
  return service({
    url: 'other_assets_fe',
    method: 'get'
  })
}